window.$ = window.jQuery = require("jquery");
require("svg.js");
require("tippy.js");
require("./app/blocks/main-nav");
require("./app/blocks/mail-list");
require("./app/blocks/share");
require("./app/pages/contact");
require("./app/pages/next-events");
require("./components/cookie");

require("./app/blocks/overlay-terms-conditions");

var cq = require("cq-prolyfill")({
	preprocess: true
});
require("./app/blocks/race-block-responsive");
require("./race-manager/race-manager-tools");

require("./app/pages/race-details-page");

require("./images");
